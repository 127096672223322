<template>
    <div >
        <footer class="text-bg-success p-2">
            <h4>Grupo Banklot</h4>
        </footer>
    </div>

</template>

<style scoped>
footer {
    position: fixed;
    height: 60px;
    bottom: 0;
    width: 100%;
    background-color: rgb(13, 85, 22);
    color: rgb(227, 222, 222);
}
</style>