<template>
    <div class="text-center">
        <HeaderView />
        Crea usuarios
        <FooterView />
    </div>
</template>

<script>
    import HeaderView from '@/components/HeaderView.vue';
    import FooterView from '@/components/FooterView.vue';

    export default{
        name: "CrearUsuarios",
        components: {
                HeaderView,
                FooterView
            },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>

<style scoped></style>