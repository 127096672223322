<template>
    <div>



        <nav class="navbar navbar-expand-lg bg-success">
            <div class="container">
                <a class="navbar-brand text-bg-success" href="#" >Hist&oacute;rico de Consultas</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse"  :class="!visible?'collapse':''" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link active text-bg-success" aria-current="page" href="dashboard" exact>Home</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-bg-success" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Usuarios
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="crearusuarios">Crear</a></li>
                                <li><a class="dropdown-item" href="listausuarios">Lista</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-bg-success" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Consultas
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="histregistroconsulta" >Hist&oacute;rico Registro Consultas</a></li>
                                <li><a class="dropdown-item" href="histconsultresultados" >Hist&oacute;rico Consulta Resultados</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active text-bg-success" aria-current="page" href="logout">Salir</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    export default{
        methods: {
            prueba(){
                alert('Hola Mundo');
            }
        }
    }
</script>

<style scoped></style>