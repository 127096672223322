<template>
    <div class="text-center">
        <HeaderView />
        <br>
        <p class="fs-2 fw-bolder"> Hist&oacute;rico de Consultas de Resultados    </p>
        <br>
        <div class="wrapper">
            <div id="formContent" >
                <!-- Tabs Titles -->
                <form v-on:submit.prevent="obtieneConsultaResultados">
                    <div class="container ">
                        <div class="row ">
                            <div class="mb-3 mt-3 col-5 text-md-end" >
                                <span class="h3 ">Fecha:</span>
                            </div>
                            <div id="FechaForm" class="mb-3 mt-3 col-6 text-md-start">
                                <input type="date" id="FechaForm" v-model="fechas" name="FechaForm" min="min" max="max" locale="es" format="DD-MM-YYYY"/>
                            </div>
                            <div class="mb-1 mt-1 col-1"></div>
                        </div>
                        <div class="row ">
                            <div class="mb-3 mt-3 col-5 text-md-end">
                                <span class="h3">Usuario:</span>
                            </div>
                            <div class="mb-3 mt-3 col-6 text-md-start" >
                                <select v-model="usuario_" name="usuario_" id="usuario_" class="form-select" required>
                                    <option v-for="opcion in usuarios" :key="opcion.id_usuario" :value="opcion.id_usuario" >
                                        {{ opcion.username }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3 mt-3 col-1"></div>
                        </div>
                    </div>
                    <input type="submit" class="fadeIn fourth" value="Mostrar">
                </form>
            </div>
        </div>
        <br><br>
        <div class="w-75 table-responsive-lg" style="display: inline-block;">
            <table class="table table-bordered table-striped table-hover" >
                <thead>
                    <tr>
                        <th rowspan="2" valign="middle">Nro</th>
                        <th rowspan="2" valign="middle">Fecha</th>
                        <th rowspan="2" valign="middle">Hora</th>
                        <th rowspan="2" valign="middle">Usuario</th>
                        <th rowspan="2" valign="middle">Producto</th>
                        <th rowspan="2" valign="middle">Turno Sorteo</th>
                        <th rowspan="2" valign="middle">Fecha Sorteo</th>
                        <th rowspan="2" valign="middle">Tipo Sorteo</th>
                        <th colspan="7">Desde</th>
                    </tr>
                    <tr>
                        <th >Direccion IP</th>
                        <th >Ciudad</th>
                        <th >Pais</th>
                        <th >Cod Pais</th>
                        <th >VPN</th>
                        <th >Proveedor Servicio</th>
                        <th >Tipo Conexion</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="lista in ListaConsulta" :key="lista.row_number" >
                        <td >{{ lista.row_number }}</td>
                        <td>{{ lista.fecha_consulta }}</td>
                        <td>{{ lista.hora_consulta }}</td>
                        <td>{{ lista.username }}</td>
                        <td>{{ lista.tproducto }}</td>
                        <td>{{ lista.tturno_sorteo }}</td>
                        <td>{{ lista.dfecha_sorteo }}</td>
                        <td>{{ lista.tconsulta }}</td>
                        <td>{{ lista.tdireccionip_consulta }}</td>
                        <td>{{ lista.tciudad_consulta }}</td>
                        <td>{{ lista.tpais_consulta }}</td>
                        <td>{{ lista.tcod_pais_consulta }}</td>
                        <td>{{ lista.bisvpn }}</td>
                        <td>{{ lista.tisp }}</td>
                        <td>{{ lista.ttipo_conexion }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br><br>
        <FooterView />
        <br><br>
    </div>
</template>

<script>
    import HeaderView from '@/components/HeaderView.vue';
    import FooterView from '@/components/FooterView.vue';
    import constantes from '../../components/ConfigView.vue';
    import funcionesJS from '../../libs/funciones';
    import axios from 'axios';

    export default{
        name: "HistRegistroConsulta",
        components: {
                HeaderView,
                FooterView
            },
        data() {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            // 15th two months prior
            const minDate = new Date(today);
            minDate.setMonth(minDate.getMonth() - 2);
            minDate.setDate(15);
            // 15th in two months
            const maxDate = new Date(today);
            maxDate.setMonth(maxDate.getMonth() + 2);
            maxDate.setDate(15);
            return {
                usuario_: null,
                fechas: '',
                min: minDate,
                max: maxDate,
                usuarios: [{row_number: '0',id_usuario: '0',username: 'Seleccione un Usuario'}],
                /*
                usuarios: [
                    { value: null, text: 'Seleccione un Usuario' }
                ],
                */
                ListaConsulta: null
            }
        },
        methods: {
            obtieneConsultaResultados(){
                if (this.fechas==''){
                    alert('La Fecha, NO puede estar vacio');
                    return;
                } else{
                    if (`${this.usuario_}`=='0'){
                        this.usuario_ = null;
                    }
                    let url = `${constantes.API_LOCATION}/api/hist/getConsResultados/${this.usuario_}/'${this.fechas}'`;
                    axios.get(url).then(data => {
                        this.ListaConsulta = data.data;
                    });
                    this.usuario_=0;
                }
            }    
        },
        mounted: async function() {
            let url = `${constantes.API_LOCATION}/api/hist/getUsuarios/true`;
            axios.get(url).then(data => {
                data.data.forEach(e => {
                    //Se agregan los datos de los usuarios a lo que mostrara el combo de usuarios 
                    this.usuarios = this.usuarios.concat({ row_number: `${e.row_number}`,id_usuario: `${e.id_usuario}`,username: `${e.username}` });
                    //Ordenamos los datos dentro del array por valor
                    this.usuarios.sort((a, b) => {
                        if(a.value < b.value){
                            return -1;
                        } else if(a.value > b.value){
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                });
            });
            //Asignamos el valor por defecto al combo de usuarios
            this.usuario_ = this.usuarios[0].id_usuario;
            //Asignamos de la fecha del dia de consulta al control de fechas
            this.fechas = await funcionesJS.requestFECHAcontrolDATE('');
            //Convierte la fecha a formato dd-mm-yyy
            //this.fechas = this.fechas.split("-").reverse().join("-");
        }
    }
</script>

<style scoped>
/* BASIC */
html {
    background-color: #2bb062;
}

body {
    font-family: "Poppins", sans-serif;
    height: 100vh;
}

a {
    color: #0d5e35;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
}

h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px; 
    color: #cccccc;
}

/* STRUCTURE */
.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
}

#formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 10px;
    width: 85%;
    max-width: 950px;
    position: relative;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    text-align: center;
}

/* TABS */
h2.inactive {
    color: #cccccc;
}

h2.active {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/
input[type=button], input[type=submit], input[type=reset]  {
    background-color: #136228;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
    box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
    background-color: #27a854;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

input[type=text] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
}

input[type=text]:placeholder {
    color: #cccccc;
}

.fadeIn {
    opacity:1;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    animation-duration:1s;
}

.fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}
/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
}

.underlineHover:hover {
    color: #0d0d0d;
}

.underlineHover:hover:after{
    width: 100%;
}

/* OTHERS */
*:focus {
    outline: none;
} 
</style>
