<template>
<div>
Salimos del Programa
</div>
</template>

<script>
    import constantes from '../components/ConfigView.vue';
    import { mapState, mapActions } from 'vuex';
    import axios from 'axios';
    export default{
        name: "LogOut",
        computed:{
            ...mapState(['session'])
        },
        data() {
            return {
                Respuesta: null
            }
        },
        methods:{
            ...mapActions(['cerrarSesion']),
            logout(){
                let url = `${constantes.API_LOCATION}/api/auth/logout/${this.session}`;
                axios.get(url).then(data => {
                    this.Respuesta = data.data;
                });
                this.cerrarSesion();
                this.$router.push('/');
            }
        },
        mounted: function(){
            this.logout();
        }
    }
</script>
