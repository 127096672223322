<template>
    <div class="text-center">
        <HeaderView />
        <br>
        <p class="fs-2 fw-bolder"> Lista de Usuarios </p>
        <br>
        <div class="container table-responsive=lg">
        <table class="table table-sm table-bordered table-striped table-hover">
            <thead>
                <tr> 
                    <th scope="col" rowspan="2" valign="middle">Nro</th>
                    <th colspan="2"></th>
                    <th scope="col" rowspan="2" valign="middle">Nombre de Usuario</th>
                    <th scope="col" rowspan="2" valign="middle">Nombre y Apellido</th>
                    <th scope="col" rowspan="2" valign="middle">Correo Electr&oacute;nico</th>
                    <th scope="col" rowspan="2" valign="middle">Habilitado</th>
                    <th scope="col" rowspan="2" valign="middle">Duracion Sesi&oacute;n</th>
                    <th scope="col" rowspan="2" valign="middle">Fecha Creaci&oacute;n</th>
                    <th scope="col" rowspan="2" valign="middle">Fecha Actualizaci&oacute;n</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="lista in ListaConsulta" :key="lista.row_number">
                    <td scope="row">{{ lista.row_number }}</td>
                    <td>
                        <button type="button" class="btn btn-default" @click="editar(lista.id_usuario)"><i class="fa fa-edit"></i></button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-default" @click="borrar(lista.id_usuario)"><i class="fa fa-trash"></i></button>
                    </td>
                    <td>{{ lista.username }}</td>
                    <td>{{ lista.nombre_apellidos }}</td>
                    <td>{{ lista.email }}</td>
                    <td>{{ lista.habilitado }}</td>
                    <td>{{ lista.duracion_session }}</td>
                    <td>{{ lista.fecha_creacion }}</td>
                    <td>{{ lista.fecha_act }}</td>
                </tr>
            </tbody>
        </table>
        <br><br><br>
        </div>
        <FooterView />
    </div>
</template>

<script>
    import HeaderView from '@/components/HeaderView.vue';
    import FooterView from '@/components/FooterView.vue';
    import constantes from '../../components/ConfigView.vue';
    import { mapState } from 'vuex';
    import axios from 'axios';

    export default{
        name: "ListaUsuarios",
        components:{
            HeaderView,
            FooterView
        },
        data(){
            return {
                ListaConsulta: null,
                v_id_usuario: null
            }
        },
        computed:{
            ...mapState(['token'])
        },
        methods: {
            async editar(id_usuario) {
                this.$router.push('edit'+id_usuario);
 //               let url = `${constantes.API_LOCATION}/api/user/edit/${id_usuario}`;
//                await axios.get(url).then(data => {
//                    this.v_id_usuario = data.data[0].id_usuario;
//                    this.$router.push('editausuarios');
//                });
                /*
                */
            },
            //borrar(id_usuario) {
                //let url = `${constantes.API_LOCATION}/api/user/delete/${id_usuario}`;
                //axios.delete(url).then(data => {
                    //this.DatosUsuario = data.data[0].id_usuario;
                //});
            //}
            /*
            */
        },
        mounted: function(){
            console.log(this.token);
            let url = `${constantes.API_LOCATION}/api/user/getUsuarios/false`;
            axios.get(url, {
                headers: {
                    "x-access-token": this.token
                }
            }).then(data => {
                this.ListaConsulta = data.data;
            });
        }
    }
</script>

<style scoped></style>