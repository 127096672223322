<script>
export default {
    name: 'ConfigView',
//    API_LOCATION: 'http://127.0.0.1:3405',
    API_LOCATION: 'http://137.184.233.207:3405',

    SESSION_ID: '',
    TOKEN: '',
    SESSION_LOGGEDIN: false
}
</script>