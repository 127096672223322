<template>
    <div >
        <HeaderView />
        <div class="container">
            <br>
            <div class="text-center">
                <p class="fs-3">Editando el usuario {{usuario}}</p>
            </div>
            <br>
            <form v-on:submit.prevent="actUSUARIOS" class="form-group needs-validation" novalidate> 
                <div class="row g-2 justify-content-center" >
                    <div class="col-md-3 left"> 
                        <label for="validationCustom01" class="form-label h5">Nombre:</label>
                        <input type="text" class="form-control" id="validationCustom01" v-model="form.nombre_apellidos" required>
                        <div class="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div class="col-md-3 left">
                        <label for="validationCustomUsername" class="form-label h5">Usuario:</label>
                        <input type="text" class="form-control" id="validationCustomUsername" v-model="form.username" required>
                        <div class="valid-feedback">
                            Please choose a username.
                        </div>
                    </div>
                </div><br>
                <div class="row g-2 justify-content-center">
                    <div class="col-md-4 left">
                        <label for="validationCustom02" class="form-label h5">Correo Electronico:</label>
                        <input type="text" class="form-control" id="validationCustom02" v-model="form.email" required>
                        <div class="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div class="col-md-2 left">
                        <label for="validationCustom03" class="form-label h5">Duracion de Sesion:</label>
                        <input type="text" class="form-control" id="validationCustom03" v-model="form.duracion_session" required>
                        <div class="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div><br>
                <div class="row g-3 justify-content-center">
                    <div class="col-md-3" role="group">
                        <label for="validationCustom04" class="form-label h5">Tipos de Usuario:</label>
                        <select class="form-select" size="2" multiple v-model="tipoUSUARIO_" name="tipoUSUARIO_" id="tipoUSUARIO_" >
                            <option v-for="opcion in TIPOusuarios" :key="opcion.id_tipo_usuario" :selected="tipoUSUARIO_.indexOf (opcion.id_tipo_usuario) != -1" :value="opcion.id_tipo_usuario">
                                {{ opcion.ttipo_usuario }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="form-check col-md-2">
                        <input class="form-check-input" type="checkbox" v-model="form.habilitado" id="invalidCheck" required>
                        <label class="form-check-label h5" for="invalidCheck">
                            Habilitado
                        </label>
                        <div class="invalid-feedback">
                            You must agree before submitting.
                        </div>
                    </div>
                </div><br>
                <div class="row g-2 justify-content-center">
                    <button class="btn btn-success col-md-2" type="submit" >Actualiza Datos</button>&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-outline-danger col-md-2" type="button" @click="cancelar()">Cancelar</button>
                </div>
            </form>
        </div>
        <FooterView />
    </div>
</template>

<script>
    import HeaderView from '@/components/HeaderView.vue';
    import FooterView from '@/components/FooterView.vue';
    import constantes from '../../../components/ConfigView.vue';
    import { mapState } from 'vuex';
    import axios from 'axios';

    export default{
        name: "ListaUsuarios",
        components:{
            HeaderView,
            FooterView
        },
        data(){
            return {
                usuarioID: null,
                tipoUSUARIO_: [],
                usuario: "",
                TIPOusuarios: [],
                form: {
                    nombre_apellidos: "",
                    username: "",
                    email: "",
                    duracion_session: null,
                    habilitado: null,
                }
            }
        },
        computed:{
            ...mapState(['token'])
        },
        methods: {
            actUSUARIOS(){

            },
            cancelar(){
                this.$router.push('listausuarios');
            }
        },
        mounted: async function(){
            this.usuarioID = this.$route.params.id;
            let url = `${constantes.API_LOCATION}/api/user/edit/${this.usuarioID}`; 
            await axios.get(url, {
                headers: {
                    "x-access-token": this.token
                }
            }).then(data => {
                this.usuario = data.data[0].nombre_apellidos;
                this.form.nombre_apellidos = data.data[0].nombre_apellidos;
                this.form.username = data.data[0].username;
                this.form.email = data.data[0].email;
                this.form.duracion_session = data.data[0].duracion_session;
                this.form.habilitado = data.data[0].habilitado;
            });
            url = `${constantes.API_LOCATION}/api/user/getTipoUsuarios`; 
            await axios.get(url, {
                headers: {
                    "x-access-token": this.token
                }
            }).then(data => {
                this.TIPOusuarios = data.data;
            });
            url = `${constantes.API_LOCATION}/api/user/getUsuariosTipoUsuarios/${this.usuarioID}`; 
            await axios.get(url, {
                headers: {
                    "x-access-token": this.token
                }
            }).then(data => {
                let v_id_tipo_usuario = '';
                for (let i = 0; i < data.data.length; i++) { //Recorremos los registros para recopilar los tipos de usuarios
                    if(v_id_tipo_usuario==''){ //Vamos colocando cada tipo de usuario en linea seprado por comas para armar un array
                        v_id_tipo_usuario = `"${data.data[i].id_tipo_usuario}"`;
                    } else{
                        v_id_tipo_usuario = v_id_tipo_usuario + ',' + `"${data.data[i].id_tipo_usuario}"`;
                    }
                }
                this.tipoUSUARIO_ = `[${v_id_tipo_usuario}]`; //Damos valor a la variable que interactua con el control de seleccion multiple
            });
        }
    }


    /*
// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
  'use strict'
// Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})()
    */
</script>

<style scoped>
.left{
    text-align:left;
}
</style>