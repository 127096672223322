import { createStore } from 'vuex'
//import constantes from '../components/ConfigView.vue'

export default createStore({
  state: {
    token: null,
    session: null
  },
  getters: {
  },
  mutations: {
    setToken(state, payload){
      state.token = payload
    },
    setSession(state, payload){
      state.session = payload
    }
  },
  actions: {
    guardarToken({commit}, token){
      commit('setToken', token);
    },
    guardarSession({commit}, session){
      commit('setSession', session);
    },
    leerToken({commit}){ //Si se refresca el navegador, se pierde la informacion del storage, entonces lo actualizamos
      if(localStorage.getItem('x-access-token')){
        commit('setToken', localStorage.getItem('x-access-token'));
      } else{
        commit('setToken', null);
      }
    },
    leerSession({commit}){ //Si se refresca el navegador, se pierde la informacion del storage, entonces lo actualizamos
      if(localStorage.getItem('session')){
        commit('setSession', localStorage.getItem('session'));
      } else{
        commit('setSession', null);
      }
    },
    cerrarSesion({commit}){
      localStorage.removeItem('x-access-token');
      localStorage.removeItem('session');
      commit('setToken', null);
      commit('setSession', null);
    }
  },
  modules: {
  }
})

