const muestrareloj = async () => {
    let fechaHora = new Date();
    let hrs = fechaHora.getHours();
    let min = fechaHora.getMinutes();
    let seg = fechaHora.getSeconds();
    let sufijo = ' am';
    if(hrs > 12) {
        hrs = hrs - 12;
        sufijo = ' pm';
    }
    if(hrs < 10) { hrs = '0' + hrs; }
    if(min < 10) { min = '0' + min; }
    if(seg < 10) { seg = '0' + seg; }

    let hora = `${hrs}:${min}:${seg} ${sufijo}`;
    return hora;
};

const requestFECHAcontrolDATE = async (fecha) => {
    try{
        let fechaSorteo;
        let DiaHoy;
        let MesHoy;
        let AnoHoy;
        let fechaRETURN;
        if (fecha == ''){
            fechaSorteo = new Date();
        } else{
            fechaSorteo = new Date(fecha);
        }
        DiaHoy = fechaSorteo.getDate();
        if (DiaHoy<10){
            DiaHoy = `0${DiaHoy}`;
        }
        MesHoy = fechaSorteo.getMonth()+1;
        AnoHoy = fechaSorteo.getFullYear();
        fechaRETURN = `${AnoHoy}-${MesHoy}-${DiaHoy}`;
        return fechaRETURN;
    }catch(error){
        //throw error;
        const mensajeERROR = {
            Error: error.message,
            Funcion: "requestFECHAcontrolDATE",
            Ubicacion: "src/libs/funciones.js",
            Linea: "19"
        }
        const fechaLOG = '';
        console.log(mensajeERROR);
        return fechaLOG;
    }
}

const requestFECHAcortaUS = async () => {
    try{
        let fechaLOG = new Date();
        fechaLOG = fechaLOG.toLocaleDateString('en-US', { 
            year:"numeric", 
            month:"numeric", 
            day:"numeric"
        })
        return fechaLOG;
    }catch(error){
        //throw error;
        const mensajeERROR = {
            Error: error.message,
            Funcion: "requestFECHAcortaUS",
            Ubicacion: "src/libs/funciones.js",
            Linea: "53"
        }
        const fechaLOG = '';
        console.log(mensajeERROR);
        return fechaLOG;
    }
}

const requestFECHAcortaES = async () => {
    try{
        let fechaLOG = new Date();
        fechaLOG = fechaLOG.toLocaleDateString('en-GB', { 
            year:"numeric", 
            month:"numeric", 
            day:"numeric"
        });
        return fechaLOG;
    }catch(error){
        //throw error;
        const mensajeERROR = {
            Error: error.message,
            Funcion: "requestFECHAcortaES",
            Ubicacion: "src/libs/funciones.js",
            Linea: "76"
        }
        const fechaLOG = '';
        console.log(mensajeERROR);
        return fechaLOG;
    }
}

const requestFECHA = async () => {
    try{
        let fechaLOG = new Date();
        fechaLOG = fechaLOG.toLocaleDateString('en-us', { 
            weekday:"long", 
            year:"numeric", 
            month:"short", 
            day:"numeric"
        })
        return fechaLOG;
    }catch(error){
        //throw error;
        const mensajeERROR = {
            Error: error.message,
            Funcion: "requestFECHA",
            Ubicacion: "src/libs/funciones.js",
            Linea: "99"
        }
        const fechaLOG = '';
        console.log(mensajeERROR);
        return fechaLOG;
    }
}

export default {
    muestrareloj,
    requestFECHAcontrolDATE,
    requestFECHA,
    requestFECHAcortaUS,
    requestFECHAcortaES
}
