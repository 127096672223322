<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default{
  methods:{
    ...mapActions(['leerToken', 'leerSession', 'cerrarSesion'])
  },
  created(){
    this.leerToken();
    this.leerSession();
  }
}
</script>

<style></style>
