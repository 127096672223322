import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/DashboardView.vue';
import HistConsultaResultados from '../views/consultas/HistConsultaResultados.vue';
import HistRegistroConsulta from '../views/consultas/HistRegistroConsulta.vue';
import LogOut from '../views/LogOutView.vue';
import ListaUsuarios from '../views/usuarios/ListaUsuariosView.vue';
import EditaUsuarios from '../views/usuarios/edit/EditaUsuarioView.vue';
import CrearUsuarios from '../views/usuarios/CrearUsuariosView.vue';
const routes = [
  { //path: '/'
    path: '/',
    name: 'home',
    component: HomeView
  },
  {//path: '/dashboard'
  path: '/dashboard',
  name: 'DashboardView',
  component: DashboardView
  },
  {//path: '/histconsultresultados'
  path: '/histconsultresultados',
  name: 'HistConsultaResultados',
  component: HistConsultaResultados
  },
  {//path: '/histregistroconsulta'
  path: '/histregistroconsulta',
  name: 'HistRegistroConsulta',
  component: HistRegistroConsulta
  },
  {//path: '/logout'
  path: '/logout',
  name: 'LogOut', 
  component: LogOut
  },
  {//path: '/listausuarios'
    path: '/listausuarios',
    name: 'ListaUsuarios', 
    component: ListaUsuarios
    },
  {//path: '/editausuarios'
    path: '/edit:id',
    name: 'EditaUsuarios', 
    component: EditaUsuarios
    },
  {//path: '/crearusuarios'
  path: '/crearusuarios',
  name: 'CrearUsuarios', 
  component: CrearUsuarios
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router; 
