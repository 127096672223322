<template>
    <div class="text-center">
        <HeaderView />
        <br><br><br>
        <p class="fs-2 fw-bolder ">Bienvenidos a la Consulta</p>
        <FooterView />
    </div>
</template>

<script>
    import HeaderView from '@/components/HeaderView.vue';
    import FooterView from '@/components/FooterView.vue';
    //import funcionesJS from '../libs/funciones';
    export default{
        name: "DashboardView",
        components: {
                HeaderView,
                FooterView
            },
        methods:{
            
            //setTimeout(funcionesJS.reloj(), 1000)
        }
    }
</script>

<style scoped></style>